import React, { useEffect, useState } from "react";
import { getUser, resetUserSession } from './service/AuthService';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'

const logUrl = 'https://api.bunafr.net/roast';
const apiKey = 'NIuewCf2gV1EEH67RBqbF2CahSnCpOOg3ARWWA2Q';
const logSecret = "dghsgd7676hsjdhjs_hj";

const Sessions = () => {
    const user = getUser();
    const name = user && user.name ? user.name : '';
    const navigate = useNavigate();
    const [list, setList] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
    
    const [sessionContent, setSessionContent] = useState(null);
    

    useEffect(() => {
        // Use useEffect to handle navigation after component mount
        if (name === '') {
            navigate('/login');
        } else {
            listHandler();
        }
    }, [name, navigate]);

    const listHandler = async (event) => {
        event && event.preventDefault();
        setErrorMessage(null);

        try {
            const response = await axios.post(logUrl, {
                sn: "*",
                secret: logSecret,
                data:"000",
                command: "loadall"
            }, {
                headers: { 'x-api-key': apiKey, 'Content-Type': 'application/json' }
            });

            setList(response.data.data);
        } catch (error) {
            setErrorMessage(error.response?.data?.message || 'Server error');
        }
    };
    const logoutHandler = () => {
        resetUserSession();
        navigate('/login'); // Navigate to "/login"
    }

    const padNumber = (num, size) => {
        return num.toString().padStart(size, '0');
    }

    const convertsessiontoRoastPart = (data) => {
        // extract from data
        var t1 = "310";
        var t2 = "330";
        var t3 = "360";
        var h1 = "09";
        var h2 = "08";
        var h3 = "07";
        var f1 = "04";
        var f2 = "04";
        var f3 = "04";

        var prevItem = data[0];
        var changePoints = [];
        changePoints.push(data[0]);
        for (let i = 1; i < data.length; i++) {
            if (( data[i].h != prevItem.h || data[i].f != prevItem.f) && (data[i].h != null) && (data[i].h != 0)){
                changePoints.push(data[i]);
            }
            prevItem = data[i];
        }

        console.log("Generating profile from data extracted: " + JSON.stringify(changePoints));

        var steps = [];

        for (let i = 1; i < changePoints.length && i <= 11 ; i++) {
             var t = padNumber(Math.floor(changePoints[i].bt),3);
             var h = padNumber(changePoints[i].h,2);
             var f = padNumber(changePoints[i].f,2);
             var step =  '"03' + t + '100' + h + f + '100010"';
             steps.push(step);
        }
        
        if (changePoints.length >= 3) {
            return steps.join(',');
        }
        else {
            console.log("Generating profile from data failed, returning default profile");
            return [
             '"03' + t1 + '100' + h1 + f1 + '100010"',
             '"03' + t2 + '100' + h2 + f2 + '100010"',
             '"03' + t3 + '100' + h3 + f3 + '100010"'
             ].join(',');
        }
    }

    const generateProfile = (data) => {

        // extract from data
        const loadT = "302";
        const topT = "395";
        const profileBody = convertsessiontoRoastPart(data);
        let steps_num = Math.floor(profileBody.length/18) + 7;
        const profileStart = '{"id":"0001","version":"1","coffee":"Generic","level":"Light","step_num":' + steps_num + ',"store":"ram","step":["010001001004100000","02' + loadT + '1000904101010","020001200904100010",';
         const profileEnd = ',"04' + topT + '1000010100010","051800000004040101","060000300004041101","061200000000000000"]}';
        return profileStart + profileBody + profileEnd;
    }

    const convert2V1 = async (data) => {
        return generateProfile(data);
    }

    const getProfile = async (data) => {
        let profile = await convert2V1(data);
        // Convert the profile object to a JSON string
    const jsonData =  profile ; //JSON.stringify(profile, null, 2); // Pretty print with 2 spaces for readability

    // Create a Blob from the JSON string
    const blob = new Blob([jsonData], { type: 'application/json' });

    // Create an object URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create an anchor element and trigger the download
    const a = document.createElement('a');
    a.href = url;
    a.download = 'profile.json';  // File name for the download
    document.body.appendChild(a); // Required for Firefox
    a.click();  // Trigger the download

    // Clean up
    document.body.removeChild(a);
    URL.revokeObjectURL(url); // Release the object URL
    }

    return (
        <div>
            {name !== '' ? (
                <>
                   {/* Hello {name}! Sessions. <br />
                    <input type="button" value="Logout" onClick={logoutHandler} />
                    */}
                    <button onClick={listHandler}>Fetch Sessions</button>

                    {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}

                    {list && list.length > 0 ? (
                    <table border="0" cellPadding="5" style={{ marginTop: "20px", borderCollapse: "collapse" }}>
                    <thead>
                    <tr>
                    <th>SN</th>
                    <th>date</th>
                    <th>meta</th>
                    <th>session</th>
                    </tr>
                    </thead>
                    <tbody>
                    {list.map((session, index) => (
                    <tr key={index}>
                         <td>{session.sn.S || "N/A"}</td>
                        <td>{session.timestamp.S || "N/A"}</td>
                         <td>{session.meta.S || "N/A"}</td>
                         <td><button onClick={() => setSessionContent(session.data.S)}>View</button></td>
                        {/*} <td>{session.data.S || "N/A"}</td>*/}
                    </tr>
                    ))}
                    </tbody>
                    </table>
                        ) : (
                        <p>No sessions available</p>
                    )}

                    {/*

                    {sessionContent && (
                        <div>
                            <h3>Session </h3>
                            <pre>{JSON.stringify(sessionContent, null, 2)}</pre>
                        </div>
                    )}  
                        */}

                <h2>Graph</h2>
                { sessionContent && <button onClick={() => getProfile(sessionContent)}>Generate Profile</button>  }
              
                   { sessionContent && <ResponsiveContainer width="100%" height={300}>
                        <LineChart data={JSON.parse(sessionContent)}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="tp" label={{ value: "Time Point", position: "insideBottom", offset: -5 }} />
                            <YAxis label={{ value: "Temperature", angle: -90, position: "insideLeft" }} />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey="int1" stroke="#8884d8" name="Int Temp" />
                            <Line type="monotone" dataKey="bt" stroke="#82ca9d" name="BT Temp" />
                            <Line type="monotone" dataKey="ror" stroke="#82ca00" name="RoR" />
                            <Line type="monotone" dataKey="f" stroke="#ff7300" name="fan" />
                            <Line type="monotone" dataKey="h" stroke="#ff0000" name="heat" />
                            <Line type="monotone" dataKey="a" stroke="#007300" name="agitator" />
                        </LineChart>
                    </ResponsiveContainer>
                }
                </>
            ) : (
                // Content when name is empty
                null
            )}
        </div>
    );
}

export default Sessions;
