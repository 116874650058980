import React, { useEffect, useState } from "react";
import { getUser, resetUserSession } from './service/AuthService';
import { useNavigate } from 'react-router-dom';
//import { useDropzone } from 'react-dropzone';
//import AWS from 'aws-sdk';

const Ota = () => {
    const user = getUser();
    const name = user && user.name ? user.name : '';
    const navigate = useNavigate();
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileUplaodedSuccesfully, setFileUploadedSuccessfully] = useState(false);


    useEffect(() => {
        // Use useEffect to handle navigation after component mount
        if (name === '') {
            navigate('/login');
        }
    }, [name, navigate]);

    const logoutHandler = () => {
        resetUserSession();
        navigate('/login'); // Navigate to "/login"
    }

    const onFileChange = (event)  => {
        setSelectedFile( event.target.files[0]);
    }

    const onFileUpload = ()  => {
      if (selectedFile) {
        const formData = new FormData();
        formData.append(
          "OTA file",
          selectedFile,
          selectedFile.name
        )
        // call API
        console.log(formData);
        setSelectedFile(null);
        setFileUploadedSuccessfully(true);
      }
    }

    const fileData = ()  => {
        if (selectedFile) {
          return (
          <div>
            <h2>File Details:</h2>
            <p>File Name: {selectedFile.name}</p>
          </div>
          );
        } else if (fileUplaodedSuccesfully) {
            return (
            <div>
              <br />
              <h4> File Uploaded Succesfully</h4>
            </div>
            );
        } else {
          return (<div>Choose a file </div>);
        }
    }

    return (
        <div>
            {name !== '' ? (
                <>
                    Hello {name}! OTA. <br />
                    <input type="button" value="Logout" onClick={logoutHandler} />

                    <div>
                    <input type="file" onChange = {onFileChange}/>
                
                    <button onClick={onFileUpload}>Upload</button>

                    </div>
                   {fileData()}
                </>
            ) : (
                // Content when name is empty
                null
            )}
        </div>
    );
}

export default Ota;
