import React, { useEffect, useState } from "react";
import { getUser, resetUserSession } from './service/AuthService';
import { useNavigate } from 'react-router-dom';
import ReactHtmlParser from 'html-react-parser';

const Control = () => {
    const user = getUser();
    const name = user && user.name ? user.name : '';
    const navigate = useNavigate();
    const [htmlContent, setHtmlContent] = useState('');


    useEffect(() => {
        // Use useEffect to handle navigation after component mount
        if (name === '') {
            navigate('/login');
        }
    }, [name, navigate]);

    useEffect(() => {
        // Read the HTML content from the file (replace 'your_file_path' with the actual path)
        fetch('bunafrtest.html')
            .then(response => response.text())
            .then(content => setHtmlContent(content))
            .catch(error => console.error('Error loading HTML file:', error));
    }, []);

    const logoutHandler = () => {
        resetUserSession();
        navigate('/login'); // Navigate to "/login"
    }

    return (
        <div>
            {name !== '' ? (
                <>
                   {/* Hello {name}! Control. <br /> */}
                    {/* {ReactHtmlParser(htmlContent)} */}
                    <table>
                    <tr><td>WebTest</td><td> <a href="https://bunafr-test.s3.amazonaws.com/66dd095d-c3e3-4e90-a65c-22db4daf8cdd/bunafrtest.html" >Open in Chrome </a> </td></tr>
                    <tr><td>Latest Production FW Flash </td><td><a href="https://bunafr-test.s3.us-east-1.amazonaws.com/66dd095d-c3e3-4e90-a65c-22db4daf8cdd/BunafrFWFlasherNEWPCB39.html" >Open in Chrome </a></td></tr>
                    <tr><td>Latest Test NEW HW FW Flash </td><td> <a href="https://bunafr-test.s3.us-east-1.amazonaws.com/66dd095d-c3e3-4e90-a65c-22db4daf8cdd/BunafrFWFlasherNEWPCB43.html" >Open in Chrome </a></td></tr>
                    <tr><td>Latest Dev Simulator FW Flash </td><td> <a href="https://bunafr-test.s3.us-east-1.amazonaws.com/fb84bded-0343-4dad-869f-1b7d147a50ed/BunafrFWFlasherSim1.html" >Open in Chrome </a></td></tr>
                    </table>
                    {/*<input type="button" value="Logout" onClick={logoutHandler} /> */}
                </>
            ) : (
                // Content when name is empty
                null
            )}
        </div>
    );
}

export default Control;
