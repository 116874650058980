import React, { useState, useEffect } from "react";
import { getUser, resetUserSession } from './service/AuthService';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'


const logUrl = 'https://api.bunafr.net/log';
const apiKey = 'NIuewCf2gV1EEH67RBqbF2CahSnCpOOg3ARWWA2Q';
const logSecret = "dghsgd7676hsjdhjs_hj";

const Logs = () => {
    const user = getUser();
    const name = user && user.name ? user.name : '';
    const navigate = useNavigate();

    const [snList, setSnList] = useState([]);
    const [logs, setLogs] = useState([]);
    const [selectedSN, setSelectedSN] = useState(null);
    const [logContent, setLogContent] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        if (name === '') {
            navigate('/login');
        } else {
            listHandler();
        }
    }, [name, navigate]);

    const logoutHandler = () => {
        resetUserSession();
        navigate('/login');
    };

    const listHandler = async (event) => {
        event && event.preventDefault();
        setErrorMessage(null);

        try {
            const response = await axios.post(logUrl, {
                sn: "0000",
                message: "{}",
                platform: "web",
                secret: logSecret,
                cmd: "getSNList"
            }, {
                headers: { 'x-api-key': apiKey, 'Content-Type': 'application/json' }
            });

            setSnList(response.data);
        } catch (error) {
            setErrorMessage(error.response?.data?.message || 'Server error');
        }
    };

    const fetchLogsForSN = async (sn) => {
        setSelectedSN(sn);
        setLogs([]);
        setErrorMessage(null);

        try {
            const response = await axios.post(logUrl, {
                sn,
                message: "{}",
                platform: "web",
                secret: logSecret,
                cmd: "getLogsforSN"
            }, {
                headers: { 'x-api-key': apiKey }
            });

            setLogs(response.data);
        } catch (error) {
            setErrorMessage(error.response?.data?.message || 'Server error');
        }
    };

    const parseLogMessages = (data) => {
        return data.map(entry => {
            const parsedEntry = JSON.parse(entry); // Convert string to JSON
            const message = parsedEntry.message;
    
            // Extract key-value pairs from the message field
            const messageData = {};
            const regex = /([\w\s]+):\s*([\d.-]+)\s*\[?[A-Za-z%]*\]?/g;
            let match;
    
            while ((match = regex.exec(message)) !== null) {
                const key = match[1].trim();
                const value = isNaN(match[2]) ? match[2] : parseFloat(match[2]);
                messageData[key] = value;
            }
    
            return {
                time: parsedEntry.time,
                platform: parsedEntry.platform,
                ...messageData
            };
        });
    };

    const fetchLogsForName = async (fileName) => {
        setLogContent(null);
        setErrorMessage(null);

        try {
            const response = await axios.post(logUrl, {
                fileName,
                message: "{}",
                platform: "web",
                secret: logSecret,
                sn:"000",
                cmd: "getExistingLogFileData"
            }, {
                headers: { 'x-api-key': apiKey }
            });
            //const rawData = response.data?.data;
            //const logData = rawData ? JSON.parse(rawData) : [];
            //setLogContent(logData);

            setLogContent(parseLogMessages(response.data?.data));

           // setLogContent(response.data?.data || 'No content found');
        } catch (error) {
            setErrorMessage(error.response?.data?.message || 'Server error');
        }
    };

    return (
        <div>
            {name !== '' && (
                <>
                 {/*   <h2>Hello {name}! Logs</h2>
                    <button onClick={logoutHandler}>Logout</button>
                    <hr />
                    */}
                    <button onClick={listHandler}>Refresh</button>
                    {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                    
                    {/* SN List Table */}
                    {snList.length > 0 && (
                        <table border="0">
                            <thead>
                                <tr>
                                    <th>SN</th>
                                    <th>Count</th>
                                    <th>View</th>
                                </tr>
                            </thead>
                            <tbody>
                                {snList.map(({ SN, Num }) => (
                                    <tr key={SN}>
                                        <td>{SN}</td>
                                        <td>{Num}</td>
                                        <td>
                                            <button onClick={() => fetchLogsForSN(SN)}>List</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}

                    {/* Logs Table */}
                    {selectedSN && logs.length > 0 && (
                        <>
                            <h3>Logs for SN: {selectedSN}</h3>
                            <table border="0">
                                <thead>
                                    <tr>
                                      {/*  <th>Log</th> */}
                                        <th>Date</th>
                                        <th>View </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {logs.map(({ fname, date }) => (
                                        <tr key={fname}>
                                           {/* <td>{fname}</td> */}
                                            <td>{new Date(date).toLocaleString()}</td>
                                            <td>
                                                <button onClick={() => fetchLogsForName(fname)}>View</button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </>
                    )}

                    {/* Log Content Display */}
                    {logContent && (
                        <div>
                          {/*  <h3>Log File Content</h3> */}
                            {/*<pre>{JSON.stringify(logContent, null, 2)}</pre>*/}
                             <h2>Graph</h2>
                                               { logContent && <ResponsiveContainer width="100%" height={300}>
                                                    <LineChart data={logContent}>
                                                        <CartesianGrid strokeDasharray="3 3" />
                                                        <XAxis dataKey="tp" label={{ value: "Time Point", position: "insideBottom", offset: -5 }} />
                                                        <YAxis label={{ value: "Temperature", angle: -90, position: "insideLeft" }} />
                                                        <Tooltip />
                                                        <Legend />
                                                        <Line type="monotone" dataKey="Intq" stroke="#8884d8" name="Int Temp" />
                                                        <Line type="monotone" dataKey="BT" stroke="#82ca9d" name="BT Temp" />
                                                        <Line type="monotone" dataKey="RT" stroke="#40ca9d" name="RT Temp" />
                                                        <Line type="monotone" dataKey="UI" stroke="#82ca00" name="UI Temp" />
                                                        <Line type="monotone" dataKey="ET" stroke="#82ca66" name="ET Temp" />
                                                        <Line type="monotone" dataKey="Amb" stroke="#82ff66" name="Amb Temp" />
                                                        <Line type="monotone" dataKey="F" stroke="#ff7300" name="fan" />
                                                        <Line type="monotone" dataKey="H" stroke="#ff0000" name="heat" />
                                                        <Line type="monotone" dataKey="A" stroke="#007300" name="agitator" />
                                                        <Line type="monotone" dataKey="CC" stroke="#ff00ff" name="crack count" />
                                                        <Line type="monotone" dataKey="LEC" stroke="#ff0000" name="error" />
                                                    </LineChart>
                                                </ResponsiveContainer>
                                            }
                        </div>
                    )}

                </>
            )}
        </div>
    );
};

export default Logs;
