import React from "react";
import { getUser, resetUserSession } from './service/AuthService';
import { useNavigate } from 'react-router-dom';

const Home = () => {
    const user = getUser();
    const name = user && user.name ? user.name : '';
   const navigate = useNavigate();
  
    const logoutHandler = () => {
        resetUserSession();
        navigate('/login');
    };

    return (
        <div>
            Bunafr Dashboard
            {name !='' && (
                <>
            <h2>Hello {name}! </h2>
            <button onClick={logoutHandler}>Logout</button>
            </>
            )
            }
            
        </div>
    )
}

export default Home;