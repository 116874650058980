import React, { useEffect } from "react";
import { getUser, resetUserSession } from './service/AuthService';
import { useNavigate } from 'react-router-dom';

const Profile = () => {
    const user = getUser();
    const name = user && user.name ? user.name : '';
    const navigate = useNavigate();

    useEffect(() => {
        // Use useEffect to handle navigation after component mount
        if (name === '') {
            navigate('/login');
        }
    }, [name, navigate]);

    const logoutHandler = () => {
        resetUserSession();
        navigate('/login'); // Navigate to "/login"
    }

    return (
        <div>
            {name !== '' ? (
                <>
                    Hello {name}! Profiles. <br />
                    <input type="button" value="Logout" onClick={logoutHandler} />
                </>
            ) : (
                // Content when name is empty
                null
            )}
        </div>
    );
}

export default Profile;
