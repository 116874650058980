import React , {useState} from "react";
import axios from "axios"
import { setUserSession } from "./service/AuthService";
import {useNavigate} from 'react-router-dom';
import { getUser, resetUserSession } from './service/AuthService';

const loginUrl = 'https://2p3j1n17x9.execute-api.us-east-1.amazonaws.com/dev/login';
const apiKey = 'NIuewCf2gV1EEH67RBqbF2CahSnCpOOg3ARWWA2Q';

          
const Login = (props) => {
    const user = getUser();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, seterrorMessage] = useState(null);
    const navigate = useNavigate();
    const name = user && user.name ? user.name : '';

    
    const submitHandler = (event) => {
        event.preventDefault();
        seterrorMessage(null);
        if (username.trim() === '' || password.trim() === '') {
            seterrorMessage("Can't be empty");
        }

        const requestConfig = {
            headers: {
                'x-api-key':apiKey
            }
        }

        const requestBody = {
            username: username,
            password: password
        }

        axios.post(loginUrl, requestBody, requestConfig).then( response => {
            setUserSession(response.data.user, response.data.token);

            navigate('/protected');

        }).catch( error => {
            if (error.response.status === 401 || error.response.status === 403) {
                seterrorMessage(error.response.data.message);
            } else {
                seterrorMessage('server error' + error.response.data.message);
            }
        })

    }

    return (
        <div>
            <form onSubmit={submitHandler}>
            <h5>Login</h5>
            username: <input type="text" value={username} onChange={event => setUsername(event.target.value)}/> <br/>
            password: <input type="password" value={password} onChange={event=> setPassword(event.target.value)}/> <br/>
            <input type="submit" value = "Login"/>   
            </form>
            {errorMessage && <p className="message">{errorMessage}</p>} 
        </div>
    )
}

export default Login;