import React, { useEffect, useState } from "react";
import { getUser, resetUserSession } from './service/AuthService';
import { useNavigate } from 'react-router-dom';
import axios from "axios";

const logUrl = 'https://api.bunafr.net/ota';
const apiKey = 'NIuewCf2gV1EEH67RBqbF2CahSnCpOOg3ARWWA2Q';
const logSecret = "gHyyE67@fgaoooi120BBBnm";

const Roasters = () => {
    const user = getUser();
    const name = user && user.name ? user.name : '';
    const navigate = useNavigate();
    const [list, setList] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);

    

    useEffect(() => {
        // Use useEffect to handle navigation after component mount
        if (name === '') {
            navigate('/login');
        } else {
            listHandler(); 
        }
    }, [name, navigate]);

    const logoutHandler = () => {
        resetUserSession();
        navigate('/login'); // Navigate to "/login"
    }

    const listHandler = async (event) => {
        event && event.preventDefault();
        setErrorMessage(null);

        try {
            const response = await axios.post(logUrl, {
                sn: "0000",
                secret: logSecret,
                "fw": "0.0.0",
	            "hw": "0.0.0",
                "command": "list",
                "type": "check_ota",
            }, {
                headers: { 'x-api-key': apiKey, 'Content-Type': 'application/json' }
            });

            setList(response.data);
        } catch (error) {
            setErrorMessage(error.response?.data?.message || 'Server error');
        }
    };

    return (
        <div>
            {name !== '' ? (
                <>
                  {/*  SHOW LIST HERE with SN |  HW | FW */}

                   {/* Hello {name}! Roasters. <br />
                    <input type="button" value="Logout" onClick={logoutHandler} />
                    */}
                      <button onClick={listHandler}>Fetch Roasters</button>

                    {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}

                    {list && list.length > 0 ? (
                    <table border="0" cellPadding="5" style={{ marginTop: "20px", borderCollapse: "collapse" }}>
                    <thead>
                        <tr>
                          <th>SN</th>
                          <th>HW</th>
                          <th>FW</th>
                        </tr>
                    </thead>
                    <tbody>
                    {list.map((roaster, index) => (
                        <tr key={index}>
                        <td>{roaster.sn || "N/A"}</td>
                        <td>{roaster.hw || "N/A"}</td>
                        <td>{roaster.fw || "N/A"}</td>
                    </tr>
                    ))}
                    </tbody>
                    </table>
                ) : (
                <p>No roasters available</p>
                )}
                </>
            ) : (
                // Content when name is empty
                null
            )}
        </div>
    );
}

export default Roasters;
